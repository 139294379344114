import axios from "axios";

// export const BASE_URL = "http://localhost:9443/";
// export const BASE_URL = 'https://api.newsgt.isitics.com/';
// export const BASE_URL = 'https://sgt.isitics.com/';
  export const BASE_URL = 'https://api.homolog-newsgt.isitics.com/';
// export const BASE_URL = 'https://api.dev.newsgt.isitics.com/';
// export const BASE_URL = "https://api.sgt.cni.com.br/";


export default axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    Accept: "application/json",
  },
});

// export const BASE_URL_NEW_API = 'http://localhost:3333/';
// export const BASE_URL_NEW_API = 'https://dev.api.sgt.isitics.com/';
 export const BASE_URL_NEW_API = 'https://qa.api.sgt.isitics.com/';
// export const BASE_URL_NEW_API = "https://api.sgt.isitics.com/";

export const newApi = axios.create({
  baseURL: BASE_URL_NEW_API,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    Accept: "application/json",
  },
});

// export const BASE_URL_NEW_BACKEND = 'http://localhost:3001/';
// export const BASE_URL_NEW_BACKEND = 'https://api.dev.sgt.isitics.com';
  export const BASE_URL_NEW_BACKEND = 'https://api.homolog.sgt.isitics.com/';
// export const BASE_URL_NEW_BACKEND = 'https://api.sgt.isitics.com/';


export const newBackend = axios.create({
  baseURL: BASE_URL_NEW_BACKEND,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    Accept: "application/json",
  },
});
