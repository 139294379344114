import React, { Suspense, lazy } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import PrivateRoute from "./comm/privateRoute.js";
import TopProgressBar from "./components/progressBar/nprogressBar.tsx";

// Lazy loading para os componentes
const Atendimento = lazy(() => import("./modules/atd/atendimento_index.jsx"));
const ResumoAtendimento = lazy(() => import("./modules/atd/resumo-atendimento.jsx"));
const AtendimentoNovo = lazy(() => import("./modules/atendimento/atendimentoNovo"));
const AtendimentoNovoRef = lazy(() => import("./modules/atendimento/atendimentoNovoRef"));
const ArquivadosMentoriaDigital = lazy(() => import("./modules/brasilMais/arquivadosMentoriaDigital"));
const ArquivadosMentoriaLean = lazy(() => import("./modules/brasilMais/arquivadosMentoriaLean"));
const ConfiguracaoBrasilMais = lazy(() => import("./modules/brasilMais/configuracaoBrasilMais"));
const ConfiguracaoMentoriaDigital = lazy(() => import("./modules/brasilMais/configuracaoMentoriaDigital"));
const ReviewTurmaMentoriaDigital = lazy(() => import("./modules/brasilMais/reviewTurmaMentoriaDigital"));
const ReviewTurmaMentoriaLean = lazy(() => import("./modules/brasilMais/reviewTurmaMentoriaLean"));
const Turma = lazy(() => import("./modules/brasilMais/turma"));
const TurmaMentoriaDigital = lazy(() => import("./modules/brasilMais/turmaMentoriaDigital"));
const TurmaNovo = lazy(() => import("./modules/brasilMais/turmaNovo"));
const TurmaNovoMentoriaDigital = lazy(() => import("./modules/brasilMais/turmaNovoMentoriaDigital"));
const UnidadesBrasilMais = lazy(() => import("./modules/brasilMais/unidadesBrasilMais"));
const UnidadesMentoriaDigital = lazy(() => import("./modules/brasilMais/unidadesMentoriaDigital"));
const Cliente = lazy(() => import("./modules/cliente/cliente"));
const ClienteNovo = lazy(() => import("./modules/cliente/clienteNovo"));
const Colaborador = lazy(() => import("./modules/colaborador/colaborador"));
const ColaboradorNovo = lazy(() => import("./modules/colaborador/colaboradorNovo"));
const DashboardBrasilMais = lazy(() => import("./modules/dashboard/dashboardBrasilMais/dashboardBrasilMais"));
const DashboardDivergencia = lazy(() => import("./modules/dashboard/dashboardDivergencia"));
const DashboardISI = lazy(() => import("./modules/dashboardISI/dashboardISI"));
const DashBoard = lazy(() => import("./modules/dashboard/dashboard.js"));
const Evento = lazy(() => import("./modules/evento/evento"));
const EventoNovo = lazy(() => import("./modules/evento/eventoNovo"));
const Homescreen = lazy(() => import("./modules/home/index.jsx"));
const Funil = lazy(() => import("./modules/indicadores/funil"));
const BusinessProcess = lazy(() => import("./modules/indicadores/kpiBusinessProcess.js"));
const BusinessSuccess = lazy(() => import("./modules/indicadores/kpiBusinessSucess.js"));
const CustomerValue = lazy(() => import("./modules/indicadores/kpiCustomerValue.js"));
const ResourceCapital = lazy(() => import("./modules/indicadores/kpiResourceCapital.js"));
const Metas = lazy(() => import("./modules/indicadores/metas"));
const Laboratorio = lazy(() => import("./modules/laboratorio/laboratorio"));
const LaboratorioNovo = lazy(() => import("./modules/laboratorio/laboratorioNovo"));
const Login = lazy(() => import("./modules/login/login"));
const LoginSuporte = lazy(() => import("./modules/loginSuporte/loginSuporte"));
const MeusDados = lazy(() => import("./modules/meusDados/meusDados"));
const NoAccess = lazy(() => import("./modules/noAccess/noAccess"));
const NotFound = lazy(() => import("./modules/notFound/notFound"));
const Notificacao = lazy(() => import("./modules/notificacao/notificacao"));
const Parametro = lazy(() => import("./modules/parametro/parametro"));
const PerfilUsuario = lazy(() => import("./modules/perfil/perfilUsuario"));
const Apropriar = lazy(() => import("./modules/producao/apropriar"));
const ApropriarEmGrupo = lazy(() => import("./modules/producao/apropriaremgrupo/apropriaremgrupo"));
const ProdutoNacional = lazy(() => import("./modules/produtoNacional/produtoNacional"));
const ProdutoNacionalNovo = lazy(() => import("./modules/produtoNacional/produtoNacionalNovo"));
const ProdutoRegional = lazy(() => import("./modules/produtoRegional/produtoRegional"));
const ProdutoRegionalNovo = lazy(() => import("./modules/produtoRegional/produtoRegionalNovo"));
const Publicacao = lazy(() => import("./modules/publicacao/publicacao"));
const PublicacaoNovo = lazy(() => import("./modules/publicacao/publicacaoNovo"));
const HistoricoReceita = lazy(() => import("./modules/receita/historico"));
const Receita = lazy(() => import("./modules/receita/receita"));
const ReceitaPage = lazy(() => import("./modules/receita/receita.tsx"));
const RelatorioDeOperacoes = lazy(() => import("./modules/relatorioDeOperacoes/relatorioDeOperacoes"));
const SolucaoIntegradora = lazy(() => import("./modules/solucaoIntegradora/solucaoIntegradora"));
const Treinamento = lazy(() => import("./modules/treinamento/treinamento"));
const TreinamentoNovo = lazy(() => import("./modules/treinamento/treinamentoNovo"));
const Unidade = lazy(() => import("./modules/unidade/unidade"));
const UnidadeNovo = lazy(() => import("./modules/unidade/unidadeNovo"));
const Usuario = lazy(() => import("./modules/usuario/usuario"));
const UsuarioNovo = lazy(() => import("./modules/usuario/usuarioNovo"));
const Visita = lazy(() => import("./modules/visita/visita"));
const VisitaNovo = lazy(() => import("./modules/visita/visitaNovo"));
const AtendimentoStakeholder = lazy(() => import("./modules/atendimento/atendimentoStakeholder.tsx"));
const DashboardNovo = lazy(() => import("./modules/dashboardNovo/DashboardNovo.tsx"));
const SolicitacoesDNPage = lazy(() => import("./modules/solicitacoesDN/solicitacoesDN.tsx"));
const CriacaoAtendimento = lazy(() => import("./modules/atendimento/criar-atendimento-stakeholder.tsx"));
const EdicaoAtendimento = lazy(() => import("./modules/atendimento/editar-atendimento-stakeholder.tsx"));
const RegistroIndicadores = lazy(() => import("./modules/atendimento/registroIndicadores.tsx"));
const RegistroIndicadoresNovo = lazy(() => import("./modules/atendimento/registroIndicadoresNovo.tsx"));
const AvaliarIndicadores = lazy(() => import("./modules/atendimento/avaliar-indicadores.tsx"));
const AvaliarIndicadoresNovo = lazy(() => import("./modules/atendimento/avaliarIndicadoresNovo.tsx"));
const IndicadoresPrograma = lazy(() => import("./modules/atendimento/registro-indicadores.tsx"));

const Routes = () => {
  return (
    <Router>
      <Suspense
        fallback={
          <div>
            <TopProgressBar />
          </div>
        }
      >
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/noAccess" component={NoAccess} />

          <PrivateRoute path="/cad/atendimento/producao/colaborador" component={Apropriar} />
          <PrivateRoute path="/cad/atendimento/producao" component={ApropriarEmGrupo} />

          <PrivateRoute path="/cad/sistema/usuario" exact component={Usuario} />
          <PrivateRoute path="/cad/sistema/usuario/novo" exact component={UsuarioNovo} />
          <PrivateRoute path="/cad/sistema/usuario/:idUsuario" exact component={UsuarioNovo} />
          <PrivateRoute path="/cad/sistema/loginSuporte" exact component={LoginSuporte} />

          <PrivateRoute path="/cad/pessoa/laboratorio" exact component={Laboratorio} />
          <PrivateRoute path="/cad/pessoa/laboratorio/novo" exact component={LaboratorioNovo} />
          <PrivateRoute path="/cad/pessoa/laboratorio/:idLaboratorio" exact component={LaboratorioNovo} />

          <PrivateRoute path="/cad/pessoa/unidade" exact component={Unidade} />
          <PrivateRoute path="/cad/pessoa/unidade/novo" exact component={UnidadeNovo} />
          <PrivateRoute path="/cad/pessoa/unidade/novo/:idUnidade" component={UnidadeNovo} />

          <PrivateRoute path="/cad/pessoa/evento" exact component={Evento} />
          <PrivateRoute path="/cad/pessoa/evento/novo" exact component={EventoNovo} />
          <PrivateRoute path="/cad/pessoa/evento/:idEvento" exact component={EventoNovo} />

          <PrivateRoute path="/cad/pessoa/treinamento" exact component={Treinamento} />
          <PrivateRoute path="/cad/pessoa/treinamento/novo" exact component={TreinamentoNovo} />
          <PrivateRoute path="/cad/pessoa/treinamento/:idTreinamento" exact component={TreinamentoNovo} />

          <PrivateRoute path="/cad/pessoa/visita" exact component={Visita} />
          <PrivateRoute path="/cad/pessoa/visita/novo" exact component={VisitaNovo} />
          <PrivateRoute path="/cad/pessoa/visita/:idVisita" exact component={VisitaNovo} />

          <PrivateRoute path="/cad/pessoa/publicacao" exact component={Publicacao} />
          <PrivateRoute path="/cad/pessoa/publicacao/novo" exact component={PublicacaoNovo} />
          <PrivateRoute path="/cad/pessoa/publicacao/:idPublicacao" exact component={PublicacaoNovo} />

          <PrivateRoute path="/cad/pessoa/colaborador" exact component={Colaborador} />
          <PrivateRoute path="/cad/pessoa/colaborador/novo" exact component={ColaboradorNovo} />
          <PrivateRoute path="/cad/pessoa/colaborador/:idColaborador" exact component={ColaboradorNovo} />

          <PrivateRoute path="/cad/pessoa/cliente" exact component={Cliente} />
          <PrivateRoute path="/cad/pessoa/cliente/novo" exact component={ClienteNovo} />
          <PrivateRoute path="/cad/pessoa/cliente/:idCliente" exact component={ClienteNovo} />

          <PrivateRoute path="/cad/atendimento/produtoNacional" exact component={ProdutoNacional} />
          <PrivateRoute path="/cad/atendimento/produtoNacional/novo" exact component={ProdutoNacionalNovo} />
          <PrivateRoute path="/cad/atendimento/produtoNacional/:idProdutoNacional" exact component={ProdutoNacionalNovo} />

          <PrivateRoute path="/cad/sistema/relatorioDeOperacoes/" exact component={RelatorioDeOperacoes} />

          <PrivateRoute path="/cad/atendimento/atendimento" exact component={Atendimento} />
          <PrivateRoute path="/cad/atendimento/:idAtendimento" exact component={ResumoAtendimento} />
          <PrivateRoute path="/cad/atendimento/atendimento/editar" exact component={EdicaoAtendimento} />
          <PrivateRoute path="/cad/atendimento/atendimento/novo" exact component={AtendimentoNovoRef} />
          <PrivateRoute path="/cad/atendimento/atendimento/stakeholder" exact component={CriacaoAtendimento} />
          <PrivateRoute path="/cad/atendimento/atendimento/novo/:idAtendimento" exact component={AtendimentoNovo} />
          <PrivateRoute path="/cad/atendimento/atendimento/:idAtendimento/rede" exact component={AtendimentoNovo} />

          <PrivateRoute path="/cad/atendimento/produtoRegional" exact component={ProdutoRegional} />
          <PrivateRoute path="/cad/atendimento/produtoRegional/novo" exact component={ProdutoRegionalNovo} />
          <PrivateRoute path="/cad/atendimento/produtoRegional/:idProdutoRegional" exact component={ProdutoRegionalNovo} />

          {/* <PrivateRoute path="/cad/atendimento/:idAtendimento/registroIndicadoresNovo" exact component={RegistroIndicadoresNovo} />
          <PrivateRoute path="/cad/atendimento/:idAtendimento/registroIndicadores" exact component={RegistroIndicadores} /> */}

          <PrivateRoute path="/cad/atendimento/:idAtendimento/registroIndicadores" exact component={IndicadoresPrograma} />

          <PrivateRoute path="/cad/atendimento/:idAtendimento/avaliarIndicadores" exact component={AvaliarIndicadores} />
          {/* <PrivateRoute path="/cad/atendimento/:idAtendimento/avaliarIndicadores" exact component={AvaliarIndicadores} /> */}

          <PrivateRoute path="/cad/sistema/perfilUsuario" component={PerfilUsuario} />
          <PrivateRoute path="/unidadesNovo" component={UnidadeNovo} />
          <PrivateRoute path="/receita" exact component={ReceitaPage} />
          <PrivateRoute path="/receita/historico/:idAtendimento" exact component={HistoricoReceita} />
          <PrivateRoute path="/cad/parametro" component={Parametro} />

          <PrivateRoute path="/cad/sistema/notificacao" component={Notificacao} />

          <PrivateRoute path="/home" component={Homescreen} />
          <PrivateRoute path="/dashboard" component={DashboardNovo} />
          <PrivateRoute path="/dashboard2" component={DashBoard} />
          <PrivateRoute path="/dashboardISI" component={DashboardISI} />
          <PrivateRoute path="/cad/sistema/divergencia" component={DashboardDivergencia} />
          <PrivateRoute path="/dashboardBrasilMais" component={DashboardBrasilMais} />

          <PrivateRoute path="/cad/sistema/funil" component={Funil} />
          <PrivateRoute path="/cad/sistema/kpi-business" exact component={BusinessSuccess} />
          <PrivateRoute path="/cad/sistema/kpi-customer-value" exact component={CustomerValue} />
          <PrivateRoute path="/cad/sistema/kpi-business-process" exact component={BusinessProcess} />
          <PrivateRoute path="/cad/sistema/kpi-resources-capital" exact component={ResourceCapital} />
          <PrivateRoute path="/cad/sistema/Metas" component={Metas} />

          <PrivateRoute path="/rel/integracao/unigest" exact component={SolucaoIntegradora} />
          <PrivateRoute path="/cad/monitoramento-dn" exact component={SolicitacoesDNPage} />

          <PrivateRoute path="/cad/sistema/meusDados" component={MeusDados} />

          <PrivateRoute path="/brasilmais/unidadesoperacionais" exact component={UnidadesBrasilMais} />
          <PrivateRoute path="/brasilmais/unidadesoperacionais/arquivados" exact component={ArquivadosMentoriaLean} />
          <PrivateRoute path="/brasilmais/configuracao" exact component={ConfiguracaoBrasilMais} />
          <PrivateRoute path="/brasilmais/mentorialean" exact component={Turma} />
          <PrivateRoute path="/brasilmais/mentorialean/novo" exact component={TurmaNovo} />
          <PrivateRoute path="/brasilmais/mentorialean/:idTurma" exact component={TurmaNovo} />
          <PrivateRoute path="/brasilmais/mentorialean/review/:idTurma" exact component={ReviewTurmaMentoriaLean} />

          <PrivateRoute path="/brasilmais/mentoriadigital" exact component={UnidadesMentoriaDigital} />
          <PrivateRoute path="/brasilmais/mentoriadigital/arquivados" exact component={ArquivadosMentoriaDigital} />
          <PrivateRoute path="/brasilmais/mentoriadigital/configuracao" exact component={ConfiguracaoMentoriaDigital} />
          <PrivateRoute path="/brasilmais/turmasmentoriadigital" exact component={TurmaMentoriaDigital} />
          <PrivateRoute path="/brasilmais/turmasmentoriadigital/novo" exact component={TurmaNovoMentoriaDigital} />
          <PrivateRoute path="/brasilmais/turmasmentoriadigital/:idTurma" exact component={TurmaNovoMentoriaDigital} />
          <PrivateRoute path="/brasilmais/turmasmentoriadigital/review/:idTurma" component={ReviewTurmaMentoriaDigital} />

          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
